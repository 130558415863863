import React,{useState} from 'react'
import EngagePageBottomHeader from '../components/EnagePageBottomHeader';
import profile from "../images/profile.png";
import edit from "../images/edit.svg";
import shineIcon from "../images/shineIcon.svg";

const EngagePage = () => {

    const [activeTab, setActiveTab] = useState('tab1'); 
  const getButtonClass = (tabName) => {
    setActiveTab(tabName);
  };


  return (
    <div className="settings-page-wrapper">
<EngagePageBottomHeader activeTab={activeTab} getButtonClass={getButtonClass} />
      {activeTab === 'tab1' && (
      <div className="container-fluid p-0">
        <div className="page-content">
          <div className="page-title">
            <h1>Overview</h1>
          </div>
          <div className="information-cards-wrapper">
          <div className="single-information-card">
                <div className="row">
                  <div className="col-md-4">
                    <div className="field-title">
                      <h4>Photo</h4>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="information-content">
                      <div className="single-information">
                        <div className="title profile-title">
                          <h5>Profile photo</h5>
                        </div>
                        <div className="profile-image">
                          <img src={profile} alt="profile " />
                          <button className="edit-btn">
                            <img src={edit} alt="icon" />
                          </button>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-information-card">
              <div className="row">
                <div className="col-4">
                  <div className="info-title">
                    <h3>Name</h3>
                  </div>
                </div>
                <div className="col-8">
                  <div className="info-section-wrapper">
                    <div className="single-info">
                      <p>Name</p>
                      <input type="text" placeholder="Super Math Tutor" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single-information-card">
              <div className="row">
                <div className="col-4">
                  <div className="info-title">
                    <h3>Instruction</h3>
                  </div>
                </div>
                <div className="col-8">
                  <div className="info-section-wrapper">
                    <div className="single-info" style={{alignItems:"flex-start"}}>
                      <p>Instruction</p>
                      <textarea name="" id="" placeholder="Hey you are a super tutor for student ages 6-12"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      )
}
{activeTab === 'tab2' && (
      <div className="container-fluid p-0">
        <div className="page-content no-content-data">
            <div className="shine-icon-wrapper">
            <img src={shineIcon} alt="shine" />
            </div>
            <strong>Super Math Tutor</strong>
        </div>
        </div>
        )}
    </div>
  )
}

export default EngagePage